import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--two-chats-new-group"
export default class extends Controller {
  connect() {
    if($(this.element).data().mode == 'new') {
      $(this.element).validate({
        rules: {
          'two_chat_api_group[form_toporder_employees][]': {
            required: true
          },
          'two_chat_api_group[wa_group_name]': {
            required: true
          }
        }
      })  
    } else {
      $(this.element).validate()
    }
    
    this.addRuleParticipant()
    this.checkDeleteParticipants()
  }

  addParticipant() {
    let id = window.crypto.randomUUID()
    $('#two-chat-api-group-form__participants-container').append(`

      <div class="two-chat-api-group-form__participant-field d-flex align-items-start">
        <div class="form-label-group string required two_chat_api_group_form_participants_participant">
          <label class="has-float-label">
            <input 
              class="form-control string required form-control-line"
              type="text"
              value=""
              name="two_chat_api_group[form_participants_attributes][${id}][participant]"
              id="two_chat_api_group_form_participants_attributes_${id}_participant">
            <span class="float-span">Participant <abbr title="required">*</abbr></span>
          </label>
        </div>
        <i class="fas fa-times clickable p-2 mt-1 font-13 ml-2" data-action="click->admin--two-chats-new-group#removeParticipant"></i>
      </div>
    `)
    this.addRuleParticipant()
    this.checkDeleteParticipants()
  }

  removeParticipant(e) {
    try {
      $(e.currentTarget).parents('.two-chat-api-group-form__participant-field').eq(0).remove()  
    } catch {
      
    }
    
    this.checkDeleteParticipants()
  }

  addRuleParticipant() {
    $('.two-chat-api-group-form__participant-field input').eq(-1).rules('add', {
      international_phone_number: true,
      required: true
    })
  }

  checkDeleteParticipants() {
    if($(this.element).data().mode != 'add') {
      $('.two-chat-api-group-form__participant-field i').toggle(
        $('.two-chat-api-group-form__participant-field').length > 1
      )      
    }

  }

}
