import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--toporder-employees-form"
export default class extends Controller {
  connect() {
    $(this.element).validate({
      rules: {
        'toporder_employee[international_phone_number]': {
          international_phone_number: true
        }
      }
    })
  }
}
