import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="back--toporder-ia-events-index"
export default class extends Controller {
  newEvent() {
    ajaxTurboStream(`/back/toporder_ia/ai_events/new?shopId=${$('#toporder_ia_page_title_shop').val()}`, false)
  }

  editEvent(e) {
    ajaxTurboStream(`/back/toporder_ia/ai_events/${$(e.currentTarget).parents('.ai_event_index_line').data().id}/edit?shopId=${$('#toporder_ia_page_title_shop').val()}`, false)
  }
}
