import { ajaxTurboStream } from 'shared/ajax_turbo_stream';

/**
 *  ToporderTable
 *
 *  Renseigner la classe 'toporder_table' aux tableaux sur lesquels doit être appliqué cette classe.
 *  Ajouter au table les attributs suivants : data-sort='<%= params[:sort] %>' data-field='<%= params[:field] %>'
 *
 *  Pour chaque colonne pouvant être triée, il faut renseigner un data-field dans le th correspondant.
 *  La valeur doit correspondre à un champ de la base de données.
 *  Exemple : data-field='name'
 *
 *  La recherche s'effectue uniquement sur les td ayant pour class 'searchable_td' et s'effectue par défaut sur le contenu d'un td.
 *  Si un data-search est renseigné, la recherche se fera sur ce dernier.
 *
 *  La pagination doit être insérée avec le helper paginate_collection qui prend en paramètre la collection.
 *
 *  Ajouter une vue index.js.erb en prenant pour exemple la vue back/products/index.js.erb
 *
**/

export class TurboToporderTable {
  constructor(table, custom_url = undefined, reload_method = 'ajax') {
    this.table = table
    this.custom_url = custom_url
    this.reload_method = reload_method
    //this.table.find('thead').css('top', $('body > nav').innerHeight())
    this.default = this.table.data()
    this.setSortable()
    this.sort = 'none'
    this.table.on('click', '.sort_arrow', (element) => {
      this.sort_table($(element.currentTarget))
    })
    //$('.digg_pagination').css('top',  $('body > nav').innerHeight())
  }

  sort_table(element) {
    this.direction = element.attr('class').split(/\s+/)[1] == 'fa-long-arrow-alt-up' ? 'sort_up' : 'sort_down'
    this.th = element.parent()
    this.table.find('th').not(this.th).removeClass('sort_up sort_down')
    if(this.direction == this.default.sort && this.th.data().field == this.default.field && this.th.hasClass(this.direction)) {
      return;
    }
    if(this.th.hasClass(this.direction)) {
      this.th.removeClass(this.direction)
      this.sort = 'none'
    } else {
      this.th.removeClass('sort_up sort_down')
      this.th.addClass(this.direction)
      this.sort = this.direction
    }
    this.reload()
  }


  filter(value) {
    if(value.length == 0) {
      this.table.find('tbody tr').toggle(true)
      return;
    }
    $.each(this.table.find('tbody tr'), (index, tr) => {
      let content = []
      $.each($(tr).find('.searchable_td'), (index, td) => {
        content.push($(td).data().search ? $(td).data().search : $(td).text().trim())
      })
      $(tr).toggle(content.join(' ').includes(value))
    })
  }

  reload() {
    this.table.addClass('toporder_table_reloading')
    let url
    if(this.custom_url) {
      let params = Object.fromEntries(new URLSearchParams(this.custom_url.split('?')[1]).entries());
      if(this.sort != 'none') {
        params['sort'] = this.sort
        params['field'] = this.th.data().field
      }
      params = new URLSearchParams(params).toString();
      url = `${this.custom_url.split('?')[0]}${params.length ? '?' + params : ''}`
    } else {
      let params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
      if(this.sort != 'none') {
        params['sort'] = this.sort
        params['field'] = this.th.data().field
      }
      params = new URLSearchParams(params).toString();
      url = `${window.location.href.split('?')[0]}${params.length ? '?' + params : ''}`
    }

    if(this.reload_method == 'ajax') {
      $.ajax({
        url: url,
        dataType: 'script'
      })      
    } else {
      ajaxTurboStream(url, false)
    }
  }

  setSortable() {
    this.table.append('<div class="toporder_table_loader"></div>')
    $.each(this.table.find('th'), (index, th) => {
      if($(th).data().field) {
        $(th).append(`<i class="fas fa-long-arrow-alt-up ml-2 sort_arrow"></i><i class="fas fa-long-arrow-alt-down sort_arrow"></i>`)
        $(th).addClass('tp_sortable')
        if(this.table.data().field == $(th).data().field) {
          $(th).addClass(this.table.data().sort)
        }
      }
    })
  }
}