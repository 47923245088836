import Back__Statistics__V2__StatisticsAbstractFilterController from "./statistics_abstract_filter_controller"
// Connects to data-controller="back--statistics--v2--statistics-general"
export default class extends Back__Statistics__V2__StatisticsAbstractFilterController {
  refresh(params) {
    $('#loading_app').addClass('d-flex')
    reloadDatas()
    $('#loading_app').removeClass('d-flex')
  }

  exportShops() {
    let data = $('.filter_card').data()
    window.location = `/back/statistics/v2/dashboard/export_turnover_shops?dates=${data.dates.split('/').join('_')}&ca=${data.ca}&group_orders=${data.grouporders}&shops=${data.shops}&sourceOrderType=${data.sourceOrderType.join('_')}`
  }
}